import React from 'react'
import { Divider, Grid, Icon } from 'semantic-ui-react'
import { PlateSuggestionService } from '@services/PlateSuggestionService';
import { FormatRow, FormatWarpper, GridRightColomnWrapper, GridWrapper, PlateRow, PlateSuggestionContainer, PlateSuggestionsContainer, PlateSuggestionsHeadingContainer, PremiumGrid, PriceWarpper } from './PlateSuggestions.styles';
import { H5, H6 } from '@styles/Global.styles';
import { IVehicleType } from '@models/ProductModels/VehicleTypes';
import { PlateFormat } from '@models/index';
import { prefetchPathname } from 'gatsby';

interface PlateSuggestionsProps {
    apiUrl:string
    selectSuggestion: Function
    suggestionsToShow: number
    vehicleType: IVehicleType
    combinationFormats: PlateFormat[]
}

interface PlateSuggestionsState {
    isLoading: boolean
    premiumSuggestions: string[],
    fixedSuggestions: string[],
    flexiSuggestions: string[],
    noSuggestions: boolean
}


class PlateSuggestions extends React.Component<PlateSuggestionsProps, PlateSuggestionsState>{
    constructor(props: PlateSuggestionsProps) {
        super(props)
        this.state = {
            isLoading: false,
            premiumSuggestions: [],
            fixedSuggestions: [],
            flexiSuggestions: [],
            noSuggestions: false
        }
    }

    mapSuggestions = (plateSuggestions:any, isFixed: boolean, isPremium: boolean) =>{
        var maxChars = this.props.vehicleType.maxChars;
        var suggestedCombinations:any[] = [];
        plateSuggestions.forEach((group: { suggestions: any[]; }) => {
            if(isFixed && (group.CombinationGroup.includes('x3') || group.CombinationGroup.includes('3x'))){
                group.suggestions.forEach(item =>{
                    if(suggestedCombinations.includes(item.Combination) == false && item.Combination.length <= maxChars){
                        suggestedCombinations.push(item.Combination);
                    }
                })
            }
            if(isPremium && (group.CombinationGroup.includes('Flexi') || group.CombinationGroup.includes('Framed'))){
                group.suggestions.forEach(item =>{
                    if(suggestedCombinations.includes(item.Combination) == false && item.Combination.length <= maxChars && item.Combination.replaceAll(' ', '').length <= 3){
                        suggestedCombinations.push(item.Combination);
                    }
                })
            }
            if(!isPremium && !isFixed && (group.CombinationGroup.includes('Flexi') || group.CombinationGroup.includes('Framed'))){
                group.suggestions.forEach(item =>{
                    if(suggestedCombinations.includes(item.Combination) == false && item.Combination.length <= maxChars && item.Combination.replaceAll(' ', '').length > 3){
                        suggestedCombinations.push(item.Combination);
                    }
                })
            }
        });
        return suggestedCombinations.slice(0,this.props.suggestionsToShow);
    }
    loadSuggestions = (combination: string) => {
        this.setState({
            isLoading: true
        });

        PlateSuggestionService.GetSuggestionsForCombination(this.props.apiUrl,1, 12, true, false, true, true, combination).then((plateSuggestions: any) => {
            if(plateSuggestions.length > 0){
                const premiumSuggestions = this.mapSuggestions(plateSuggestions, false, true);
                const flexiSuggestions = this.mapSuggestions(plateSuggestions, false, false);
                const fixedSuggestions = this.mapSuggestions(plateSuggestions, true, false);
                
                this.setState({
                    premiumSuggestions: premiumSuggestions,
                    flexiSuggestions: flexiSuggestions,
                    fixedSuggestions: fixedSuggestions,
                    isLoading: false,
                    noSuggestions: false,
                });
            }
            else {
                this.setState({
                    premiumSuggestions: [],
                    flexiSuggestions: [],
                    fixedSuggestions: [],
                    noSuggestions: true,
                    isLoading: false
                });
            }
        });
    }

    render(){
        const { isLoading, premiumSuggestions, flexiSuggestions, fixedSuggestions, noSuggestions } = this.state;
        const fixed = this.props.combinationFormats.find((format: { name: string; })=> format.name == 'Fixed Format')
        const flexi = this.props.combinationFormats.find((format: { name: string; })=> format.name == 'Flexi Format')
        const Premium = this.props.combinationFormats.find((format: { name: string; })=> format.name == 'Premium Format')

        return (
            <PlateSuggestionsContainer>
                <PlateSuggestionsHeadingContainer>
                    {!isLoading ? <H5>
                        Plate Suggestions
                    </H5> : <>
                    <Grid columns={2}>
                    <Grid.Column width={12} textAlign='left'>
                        <H5>
                            Plate Suggestions
                        </H5>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign='right'>
                            <Icon name='circle notch' size='big' className='icon_margin' loading />
                        </Grid.Column>
                    </Grid>                        
                    </>}
                </PlateSuggestionsHeadingContainer>
                {!isLoading && <GridWrapper>
                    <Grid.Row>
                        {
                            fixedSuggestions.length > 0 && fixedSuggestions.length > 0 &&
                            <Grid>
                                <Divider className='plate-suggestion-divider'></Divider>
                                <FormatRow columns={2}>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <FormatWarpper>Fixed Formats </FormatWarpper>
                                    </Grid.Column>  
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <PriceWarpper>From <span>${fixed?.basePrice}</span></PriceWarpper>
                                    </Grid.Column>                            
                                </FormatRow>
                                <PlateRow columns={2}>
                                    {fixedSuggestions.map((suggestion, index) => {
                                        if(index < 6) 
                                        return (index % 2 == 0 ?                                                
                                            <Grid.Column mobile={8} tablet={8} computer={8} key={suggestion}>
                                                <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                                    <span>
                                                        {suggestion}
                                                    </span>
                                                </PlateSuggestionContainer>
                                            </Grid.Column>  
                                            : <GridRightColomnWrapper mobile={8} tablet={8} computer={8} key={suggestion}>
                                            <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                                <span>
                                                    {suggestion}
                                                </span>
                                            </PlateSuggestionContainer>
                                        </GridRightColomnWrapper>      
                                        )
                                    })}
                                </PlateRow>                                
                            </Grid>         
                        }
                        {
                            flexiSuggestions.length > 0 && flexiSuggestions.length > 0 &&
                            <Grid>
                                <Divider className='plate-suggestion-divider'></Divider>
                                <FormatRow  columns={2}>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <FormatWarpper>Flexi Formats</FormatWarpper>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <PriceWarpper>From <span>${flexi?.basePrice}</span></PriceWarpper>
                                    </Grid.Column>
                                </FormatRow>
                                <PlateRow columns={2}>
                                {flexiSuggestions.map((suggestion, index) => {
                                    if(index < 6) 
                                    return (index % 2 == 0 ? 
                                        <Grid.Column mobile={8} tablet={8} computer={8} key={suggestion}>
                                            <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                                <span>
                                                    {suggestion}
                                                </span>
                                            </PlateSuggestionContainer>
                                        </Grid.Column>
                                        : <GridRightColomnWrapper mobile={8} tablet={8} computer={8} key={suggestion}>
                                        <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                            <span>
                                                {suggestion}
                                            </span>
                                        </PlateSuggestionContainer>
                                    </GridRightColomnWrapper>      
                                    )
                                })}
                                </PlateRow>
                            </Grid>
                        }
                        {
                            premiumSuggestions.length > 0 && premiumSuggestions.length > 0 &&
                            <PremiumGrid>
                                <Divider className='plate-suggestion-divider'></Divider>
                                <FormatRow columns={2}>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <FormatWarpper>Premium Formats</FormatWarpper>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={8} computer={8}>
                                        <PriceWarpper>From <span>${Premium?.basePrice}</span></PriceWarpper>
                                    </Grid.Column>
                                </FormatRow>
                                <PlateRow columns={2}>
                                {premiumSuggestions.map((suggestion, index) => {
                                    if(index < 6) 
                                    return (index % 2 == 0 ?
                                        <Grid.Column mobile={8} tablet={8} computer={8} key={suggestion}>
                                            <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                                <span>
                                                    {suggestion}
                                                </span>
                                            </PlateSuggestionContainer>
                                        </Grid.Column>
                                        : <GridRightColomnWrapper mobile={8} tablet={8} computer={8} key={suggestion}>
                                        <PlateSuggestionContainer onClick={() => this.props.selectSuggestion(suggestion)}>
                                            <span>
                                                {suggestion}
                                            </span>
                                        </PlateSuggestionContainer>
                                    </GridRightColomnWrapper>      
                                    )
                                })}
                                </PlateRow>
                            </PremiumGrid>
                        }                        
                        {
                            noSuggestions &&                            
                            <Grid>
                                <Divider className='plate-suggestion-divider'></Divider>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign='left'>
                                        <H6 color='#fff'>OOPS! We couldn't find any available alternatives to that combination :(</H6>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>                           
                        }
                    </Grid.Row>
                </GridWrapper>}
            </PlateSuggestionsContainer>
        )
    }
}

export default PlateSuggestions