import React, { FunctionComponent } from 'react'

import {
    CombinationContentArea,
    InfoBar,
    VehicleTypeOptions,
    VehicleTypeListItem,
    VehicleTypeItem,
    CombinationLetterCounterContainer,
    CombinationLetterCounter,
    CombinationInputContainer,
    CombinationInput,
    CombinationActionContainer,
    CombinationSearchResult,
    ResetCombination,
    PlateHole,
    CombinationInputRow,
    RestyleGuideContainer,
    RestyleGuideHeading,
    RestyleGuideDescription,
    RestyleForNewPlateMessage,
    SearchResultWrapper,
    SearchResultButtonWrapperColumn,
    RestylePageHeading,
    CombinationInputContainerRowTop,
    CombinationInputContainerRowBottom,
    BreadCrumb,
    ButtonWrapper,
    AvailabitityMessage,
    RestyleResultButtonWrapperColumn,
    RestyleButtonWrapper
} from './CombinationFinder.styles'
import { List, Grid } from 'semantic-ui-react'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { Link, navigate } from 'gatsby'
import { CheckCombinationService } from '@services/CheckCombinationService'
import { VehicleType, CombinationSettings, VehicleTypesSetting, TransactionType, TransactionTypesSetting } from '@models/ProductModels'
import QuickHelpInfoLink from './QuickHelpInfo'
import RestyleCombinationPricingOptions from '@components/CombinationPricingOptions/RestyleCombinationPricingOptions'
import { RestyleCombinationFinderProps } from '@models/RestyleCombinationFinder/RestyleCombinationFinder.models'
import { Paragraph1 } from '@styles/Global.styles'
import { GAService } from '@services/GAService'
import { GA4Event, OrderTypes, ReCaptchaThreshold } from '@utils/Constant'
import { isMobile } from '@utils/Helpers'
import { HeaderStep } from '@components/SelectStyle/SelectStyle.styles'
import SweepstakeCampaign from '@components/Campaign/SweepstakeCampaign'
import SweepstakeAds from '@components/Campaign/SweepstakeAds'
import { CampaignModel } from '@components/Campaign/CampaignModel'
import { LOCALSTORAGE_CAMPAIGN_CURRENT } from '@redux/localStorage'
import { getCurrentCheckout } from '@redux/localStorage/checkout'
import VehicleTypeSelector from './VehicleTypeSelector'

interface FinderStates {
    vehicleType: VehicleType
    combination: string
    transactionType?: TransactionType
    combinationChecking: boolean
    combinationChecked: boolean
    combinationAvailable: boolean
    oldCombination: string
    characterCombinationCount: number
    spacingCombinationCount: number,
    vehicleTheme: any
    availableForNew: boolean,
    reCaptchaToken?: string,
    reCaptchaError: string,
    isCampaignDialogOpen: boolean,
    isReturnUser: boolean,
    alreadyShow: boolean
}



class RestyleCombinationFinder extends React.Component<RestyleCombinationFinderProps, FinderStates>{
    plateFormatPricingRef: React.RefObject<RestyleCombinationPricingOptions>;

    constructor(props: RestyleCombinationFinderProps) {
        super(props);
        this.state = {
            vehicleType: props.queryParameters.vehicleType,
            combination: props.queryParameters.combination,
            transactionType: undefined,
            combinationChecking: false,
            combinationChecked: false,
            combinationAvailable: false,
            characterCombinationCount: 0,
            oldCombination: '',
            spacingCombinationCount: 0,
            vehicleTheme: theme.vehicleTheme.dark,
            availableForNew: false,
            reCaptchaToken: undefined,
            reCaptchaError: "",
            isCampaignDialogOpen: false,
            isReturnUser: false,
            alreadyShow: false
        }
        this.plateFormatPricingRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.combination !== '') {
            const combination = this.state.combination;
            this.handleCombinationCheck();
        } else{
            const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
            let campaignItem : CampaignModel
            if(campaignInLocal !== null){
                campaignItem = JSON.parse(campaignInLocal) as CampaignModel;
                if(this. props.campaigns.length > 0 && campaignItem.campaignName == this.props.campaigns[0].name && campaignItem.email){
                    this.setState({isReturnUser: true})
                }
            }
        }
    }

    getCombinationWidth = (combination: string) => {

        const largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        const smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        const spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        const totalLength = (largeCharLength * this.state.vehicleType.largeCharWidth) + (smallCharLength * this.state.vehicleType.smallCharWidth) + (spaceLength * this.state.vehicleType.spaceCharWidth);

        return {
            largeCharacterLength: largeCharLength,
            smallCharacterLength: smallCharLength,
            spaceLength: spaceLength,
            totalWidth: totalLength
        }
    }

    handleInputChange = (event: any, widgetV2: number | undefined) => {
        this.setState({ combination: event.target.value, combinationChecked: false });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();

        let combination = event.target.value;
        const combinationWidth = this.getCombinationWidth(combination);

        if(document && document.getElementById("g-recaptcha_seachCombination-v2")){
            window.grecaptcha.reset(widgetV2);
        }

        if (combinationWidth.totalWidth <= this.state.vehicleType.maxWidth)
            this.setState({
                characterCombinationCount: combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength,
                spacingCombinationCount: combinationWidth.spaceLength
            })

        if ((combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength) > this.state.vehicleType.maxChars || combinationWidth.totalWidth > this.state.vehicleType.maxWidth) {
            this.setState({
                combination: this.state.oldCombination,
                characterCombinationCount: this.getCombinationWidth(this.state.oldCombination).largeCharacterLength + this.getCombinationWidth(this.state.oldCombination).smallCharacterLength,
            });

        } else {
            var transformedInput = combination.replace(CombinationSettings.allowed, "");
            if (transformedInput !== combination) {
                this.setState({ combination: transformedInput });
            }
            this.setState({
                oldCombination: transformedInput
            });
        }
    }

    resetCombination = () => {
        this.setState({
            combination: '',
            combinationChecked: false,
            combinationAvailable: false,
            transactionType: undefined,
            characterCombinationCount: 0
        });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();
    }
    handleCombinationCheck = () => {
        this.setState({
            combinationChecking: true
        });
        const combination = this.state.combination;
        let transactionType = "";
        this.checkCombinationAvailable(combination).then((data) => {
            const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
            let campaignItem : CampaignModel
            if (data.availableForRedesign) {
                transactionType = TransactionTypesSetting.Redesign.name
                this.setState({ transactionType: TransactionTypesSetting.Redesign, combinationChecking: false, combinationChecked: true, combinationAvailable: true })

                if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.selectCombinationFormat('Redesign');

                if(campaignInLocal !== null){
                    campaignItem = JSON.parse(campaignInLocal) as CampaignModel;
                    if(campaignItem.campaignName == this.props.campaigns[0].name && campaignItem.email){
                        this.setState({isReturnUser: true})
                    }else if(campaignItem.campaignName == this.props.campaigns[0].name && !campaignItem.email) {
                        this.setState({alreadyShow: true})
                    }
                } else {
                    localStorage.setItem(LOCALSTORAGE_CAMPAIGN_CURRENT, JSON.stringify({campaignName: this.props.campaigns[0].name, email: "", time:Date.now()}));
                }
                setTimeout(() => {
                    this.setState({isCampaignDialogOpen: true})
                }, 1500)
            } else if (data.availableForUpgrade) {
                this.setState({ transactionType: TransactionTypesSetting.Upgrade, combinationChecking: false, combinationChecked: true, combinationAvailable: true })
                if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.selectCombinationFormat('Upgrade');

                transactionType = TransactionTypesSetting.Upgrade.name

                if(campaignInLocal !== null){
                    campaignItem = JSON.parse(campaignInLocal) as CampaignModel;
                    if(this.props.campaigns.length > 0 && campaignItem.campaignName == this.props.campaigns[0].name && campaignItem.email){
                        this.setState({isReturnUser: true})
                    }else if(this.props.campaigns.length > 0 && campaignItem.campaignName == this.props.campaigns[0].name && !campaignItem.email) {
                        this.setState({alreadyShow: true})
                    }
                } else {
                    localStorage.setItem(LOCALSTORAGE_CAMPAIGN_CURRENT, JSON.stringify({campaignName: this.props.campaigns[0].name, email: "", time:Date.now()}));
                }
                setTimeout(() => {
                    this.setState({isCampaignDialogOpen: true})
                }, 1500)
            } else {
                this.setState({ transactionType: undefined, combinationChecked: true, combinationChecking: false, combinationAvailable: false, isCampaignDialogOpen: false})
            }

            GAService.trackEvent('chooseCombination', {
                combination: combination,
                orderType: OrderTypes.Redesign,
                restyleVSnewdesign: "Redesign",
                isAvailable: (data.availableForRedesign || data.availableForUpgrade) ? true : false
            });

            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: combination,
                availableForRedesign: data.availableForRedesign,
                availableForUpgrade: data.availableForUpgrade,
                orderType: OrderTypes.Redesign,
                isAvailable: (data.availableForRedesign || data.availableForUpgrade) ? true : false,
                selectedSuggestion: '', 
                fromSuggestion: false
            });

            this.setState({ availableForNew: data.available })
        });
    }

    buyAsNewPlate = () => {

    }
    checkCombinationAvailable = (combination: string) => {
        return CheckCombinationService.checkCombination(this.props.apiUrl, combination, this.state.vehicleType.id).then((combinationCheckResult) => {
            return {
                availableForRedesign: combinationCheckResult.IsAvailableForRedesign,
                availableForUpgrade: combinationCheckResult.IsAvailableForUpgrade,
                available: combinationCheckResult.Available
            }
        });
    }

    chooseDesign = () => {
        navigate("/select-a-style/?combination=" + this.state.combination, {
            state: {
                combination: this.state.combination,
                plateDesign: undefined,
                vehicleType: this.state.vehicleType,
                transactionType: this.state.transactionType,
                categoryName: undefined,
                isGifting: false
            }
        });
    }
    changeVehicleType = (vehicleType: VehicleType) => {
        let vehicleTheme = null
        switch (vehicleType.name) {
            case VehicleTypesSetting.Car.name:
                vehicleTheme = theme.vehicleTheme.dark
                break;
            case VehicleTypesSetting.Motorbike.name:
                // if (this.props.vehicleTheme == theme.vehicleTheme.dark) {
                     vehicleTheme = theme.vehicleTheme.dark
                // } else {
                //    vehicleTheme = theme.vehicleTheme.white
                //}
                break;
            default:
                vehicleTheme = theme.vehicleTheme.dark
                break;
        }

        this.setState({
            vehicleType: vehicleType,
            combination: '',
            characterCombinationCount: 0,
            combinationChecked: false,
            combinationAvailable: false,
            vehicleTheme: vehicleTheme,
            availableForNew: false
        });
    }

    closeModal = () => {
        this.setState({ isCampaignDialogOpen: false })
    }
    clickSweepstakeAds =() => {
        if(this.state.combinationAvailable){
            this.setState({ isCampaignDialogOpen: true })
            if(this.state.isReturnUser) {
                this.setState({ isReturnUser: false })
            }
            if(this.state.alreadyShow){
                this.setState({ alreadyShow: false })
            }
        } else{
            this.setState({ isCampaignDialogOpen: false })
        }
    }

    handleKeyPress = (event: any) =>{
        if (event.key === "Enter") {
            this.handleCombinationCheck();
        }
    }

    getArrow = () => {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="13" viewBox="0 0 7 13" fill="none">
            <path d="M6.70719 5.79402C7.09767 6.1845 7.09767 6.81863 6.70719 7.2091L1.70915 12.2071C1.31867 12.5976 0.684547 12.5976 0.294075 12.2071C-0.0963974 11.8167 -0.0963974 11.1825 0.294075 10.7921L4.58615 6.5L0.297199 2.20793C-0.0932737 1.81745 -0.0932737 1.18333 0.297199 0.792854C0.687671 0.402382 1.3218 0.402382 1.71227 0.792854L6.71032 5.7909L6.70719 5.79402Z" fill="white"/>
          </svg>
        )
      }

    render() {
        const { vehicleType, combinationChecking, combination, combinationChecked, combinationAvailable, characterCombinationCount, vehicleTheme, availableForNew } = this.state;
        const isCampaignActive = this.props.campaigns.length > 0 && ( new Date(this.props.campaigns[0].validFrom) < new Date() && new Date() < new Date(this.props.campaigns[0].expiresOn))
        const isRestyleCampaign = this.props.campaigns.length > 0 && (this.props.campaigns[0].transactionTypes.filter(c => c.name == 'Upgrade' || c.name == 'Redesign').length > 0)
        const checkout = getCurrentCheckout()
        const isDealerOrder = checkout && checkout.isDealerOrder ? true : false;

        return (

            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign={this.props.headingAlignment}>
                        {isMobile() ? <Grid.Column width={16} textAlign="right"><HeaderStep>Step 1/3</HeaderStep></Grid.Column> : (this.props.darkTheme ? <Grid.Column width={16} textAlign="right"><BreadCrumb><span>Plate Search</span>
                        {this.getArrow()}
                        Select Design
                        {this.getArrow()}
                        Finalise Plate</BreadCrumb></Grid.Column> : <></>)}
                        <RestylePageHeading darkTheme={this.props.darkTheme} >{this.props.heading}</RestylePageHeading>
                        <Paragraph1 color={this.props.darkTheme ? theme.brand.colors.white : theme.brand.colors.black}>{this.props.subHeading}</Paragraph1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={10} widescreen={10} floated='left'>
                    <CombinationContentArea>
                        <InfoBar withCombination={combinationChecked}>
                            <VehicleTypeOptions>
                                <List horizontal>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Car.name} darkTheme={this.props.darkTheme} selected={vehicleType.id === VehicleTypesSetting.Car.id} onClick={() => this.changeVehicleType(VehicleTypesSetting.Car)}>
                                            {this.props.darkTheme ? <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Car.id} darkTheme={this.props.darkTheme} vehicleType={VehicleTypesSetting.Car} characterCombinationCount={characterCombinationCount}/> : <span>{VehicleTypesSetting.Car.name}</span>}
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} darkTheme={this.props.darkTheme} selected={vehicleType.name === VehicleTypesSetting.Trailer.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Trailer)}>
                                            {this.props.darkTheme ? <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Trailer.id} darkTheme={this.props.darkTheme} vehicleType={VehicleTypesSetting.Trailer} characterCombinationCount={characterCombinationCount}/> : <span>{VehicleTypesSetting.Trailer.name}</span>}
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Motorbike.name} darkTheme={this.props.darkTheme} selected={vehicleType.name === VehicleTypesSetting.Motorbike.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Motorbike)}>
                                            {this.props.darkTheme ? <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Motorbike.id} darkTheme={this.props.darkTheme} vehicleType={VehicleTypesSetting.Motorbike} characterCombinationCount={characterCombinationCount}/> : <span>{VehicleTypesSetting.Motorbike.name}</span>}
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                </List>
                                <QuickHelpInfoLink darkTheme={this.props.darkTheme} />
                            </VehicleTypeOptions>
                            {this.props.darkTheme ? <></> : <CombinationLetterCounterContainer>
                                <CombinationLetterCounter darkTheme={this.props.darkTheme}>
                                    {characterCombinationCount}/{vehicleType.letters}
                                </CombinationLetterCounter>
                            </CombinationLetterCounterContainer>}
                        </InfoBar>
                        <CombinationInputContainer vehicleTheme={vehicleTheme} darkTheme={this.props.darkTheme}>
                        <AvailabitityMessage>
                                    {combinationChecked && combinationAvailable && (<div style={{display:"inline-flex",color: "#3DAE2B", fontWeight: "bold"}}><svg className='svg-image' style={{marginRight:"4px"}} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99984 15.8334C13.0499 15.8334 16.3332 12.5502 16.3332 8.50008C16.3332 4.44999 13.0499 1.16675 8.99984 1.16675C4.94975 1.16675 1.6665 4.44999 1.6665 8.50008C1.6665 12.5502 4.94975 15.8334 8.99984 15.8334Z" stroke="#3DAE2B" stroke-width="2"/>
<path d="M5.66748 8.38803L7.96403 10.6847L12.3341 6.3147" stroke="#3DAE2B" stroke-width="2"/>
</svg> Plate Available</div>)}{combinationChecked && !combinationAvailable && (this.state.showNotifyButton ? (<div style={{display:"inline-flex", color: "#F3921B", fontWeight: "bold"}}><svg className='svg-image-notavailable' style={{marginRight:"6px"}} width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 8.5C8.06087 8.5 9.07828 8.07857 9.82843 7.32843C10.5786 6.57828 11 5.56087 11 4.5C11 3.43913 10.5786 2.42172 9.82843 1.67157C9.07828 0.921427 8.06087 0.5 7 0.5C5.93913 0.5 4.92172 0.921427 4.17157 1.67157C3.42143 2.42172 3 3.43913 3 4.5C3 5.56087 3.42143 6.57828 4.17157 7.32843C4.92172 8.07857 5.93913 8.5 7 8.5ZM5.57188 10C2.49375 10 0 12.4937 0 15.5719C0 16.0844 0.415625 16.5 0.928125 16.5H13.0719C13.5844 16.5 14 16.0844 14 15.5719C14 12.4937 11.5063 10 8.42813 10H5.57188Z" fill="#F3921B"/>
</svg>Plate Reserved</div>) : <div style={{display:"inline-flex", color: "#FF3333", fontWeight: "bold"}}><svg  className='svg-image' style={{marginRight:"6px"}} width="14" height="15" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.70615 2.20615C10.0968 1.81553 10.0968 1.18115 9.70615 0.790527C9.31553 0.399902 8.68115 0.399902 8.29053 0.790527L4.9999 4.08428L1.70615 0.793652C1.31553 0.403027 0.681152 0.403027 0.290527 0.793652C-0.100098 1.18428 -0.100098 1.81865 0.290527 2.20928L3.58428 5.4999L0.293652 8.79365C-0.0969726 9.18428 -0.0969726 9.81865 0.293652 10.2093C0.684277 10.5999 1.31865 10.5999 1.70928 10.2093L4.9999 6.91553L8.29365 10.2062C8.68428 10.5968 9.31865 10.5968 9.70928 10.2062C10.0999 9.81553 10.0999 9.18115 9.70928 8.79053L6.41553 5.4999L9.70615 2.20615Z" fill="#FF3333"/>
</svg>Plate Not Available</div>)}
                                </AvailabitityMessage>



                            <Grid>
                                <CombinationInputContainerRowTop>
                                    <Grid.Column width={4} floated='left'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                    <Grid.Column width={4} floated='right'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                </CombinationInputContainerRowTop>
                                <CombinationInputRow centered>
                                    <Grid.Column textAlign='center' width={16}>
                                        <CombinationInput aria-label='Search Your Combination' spellCheck='false' autocomplete="off" autocorrect="off" placeholder={vehicleType.id === 1 ? 'ABC123' : 'ABC12'} onChange={this.handleInputChange} value={combination} vehicleTheme={vehicleTheme} darkTheme={this.props.darkTheme}  onKeyPress={(event: any) => this.handleKeyPress(event)}/>
                                    </Grid.Column>
                                </CombinationInputRow>
                                <CombinationInputContainerRowBottom>
                                    <Grid.Column width={4} floated='left'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                    <Grid.Column width={4} floated='right'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                </CombinationInputContainerRowBottom>
                            </Grid>
                        </CombinationInputContainer>
                        {/* {
                            combinationChecked && combinationAvailable &&
                            <CombinationSearchResult available={true}>
                                {this.props.availableMessage}
                            </CombinationSearchResult>
                        }
                        {
                            combinationChecked && !combinationAvailable &&
                            <SearchResultWrapper>
                                <CombinationSearchResult available={false}>
                                    {this.props.unavailableMessage}
                                </CombinationSearchResult>
                                {availableForNew && <RestyleForNewPlateMessage darkTheme={this.props.darkTheme}>
                                    {this.props.alternativeMessage}
                                            </RestyleForNewPlateMessage>}
                            </SearchResultWrapper>
                        } */}
                        <CombinationActionContainer darkTheme={this.props.darkTheme}>
                            {
                                !combinationChecked &&
                                <Grid>
                                    <Grid.Column width={16} textAlign='center'>
                                        <span id='rcfcmp'><KpButton fullWidth="mobile" id='rcfcmp' loading={combinationChecking} disabled={characterCombinationCount <= 0} color={theme.brand.colors.blue} buttonType='primary' onClick={this.handleCombinationCheck} darkTheme={this.props.darkTheme}>
                                            REDESIGN MY PLATE
                                        </KpButton></span>
                                    </Grid.Column>
                                    <Grid.Column width={16} textAlign='center' className='redirect-text'>
                                        To create a new plate, <Link to={this.state.vehicleType == VehicleTypesSetting.Car ? '/create' :
                                            (this.state.vehicleType == VehicleTypesSetting.Motorbike ? '/create?vt=3' : '/create?vt=2')}>click here</Link>
                                    </Grid.Column>
                                </Grid>
                            }
                            {
                                combinationChecked && combinationAvailable &&
                                <Grid>
                                    <Grid.Column width={16} textAlign='center'>
                                        <span id='rcfc'><KpButton fullWidth="mobile" id='rcfc' color={theme.brand.colors.blue} buttonType='primary' onClick={this.chooseDesign} darkTheme={this.props.darkTheme}>
                                            CONTINUE
                                        </KpButton></span>
                                    </Grid.Column>
                                    <Grid.Column width={16} textAlign='center'>
                                        <span id='rcfrc'><ResetCombination fullWidth="mobile" id='rcfrc' onClick={this.resetCombination}>
                                            Reset Combination
                                        </ResetCombination></span>
                                    </Grid.Column>
                                    <Grid.Column width={16} textAlign='center' className='redirect-text'>
                                        To create a new plate, <Link to={this.state.vehicleType == VehicleTypesSetting.Car ? '/create' :
                                            (this.state.vehicleType == VehicleTypesSetting.Motorbike ? '/create?vt=3' : '/create?vt=2')}>click here</Link>
                                    </Grid.Column>

                                    {isCampaignActive && !this.state.isReturnUser && isRestyleCampaign && this.state.isCampaignDialogOpen && !isDealerOrder && !this.state.alreadyShow &&
                                    <SweepstakeCampaign
                                        campaign={this.props.campaigns[0]}
                                        reCaptchaSiteKey={this.props.reCaptchaSiteKey}
                                        reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}
                                        apiUrl={this.props.apiUrl}
                                        emailValidationToken={this.props.emailValidationToken}
                                        modalOpen={true}
                                        onClose={this.closeModal}
                                        />}
                                </Grid>
                            }
                            {
                                combinationChecked && !combinationAvailable &&
                                <Grid>
                                    <Grid.Row centered>
                                        <SearchResultButtonWrapperColumn maxWidth={45} marginRight={12} mobile={8} tablet={5} computer={5} largeScreen={5} widescreen={5} textAlign='center'>
                                            <span id='rcfsa'><KpButton fullWidth='mobile' textColor={theme.brand.colors.white} color={theme.brand.secondary} id='rcfsa' buttonType={this.props.darkTheme ? 'darkModeSecondary' : 'secondary'} onClick={this.resetCombination}>
                                                START AGAIN
                                            </KpButton></span>
                                        </SearchResultButtonWrapperColumn>
                                        <RestyleResultButtonWrapperColumn maxWidth={45} mobile={8} tablet={6} computer={6} largeScreen={6} widescreen={6} textAlign='center'>
                                            <RestyleButtonWrapper id='rcfbnp'><KpButton fullWidth='mobile' color={theme.brand.colors.blue} id='rcfbnp' buttonType='primary' link={availableForNew ?
                                                (this.state.vehicleType == VehicleTypesSetting.Car ? `/create/?combination=${combination}` :
                                                    (this.state.vehicleType == VehicleTypesSetting.Motorbike ? `/create/?vt=3&combination=${combination}` : `/create/?vt=2&combination=${combination}`)) : ''}
                                                disabled={!availableForNew} darkTheme={this.props.darkTheme}>
                                                BUY AS NEW PLATE
                                            </KpButton></RestyleButtonWrapper>
                                        </RestyleResultButtonWrapperColumn>
                                    </Grid.Row>
                                </Grid>
                            }
                            {
                                isCampaignActive && isRestyleCampaign && this.props.campaigns[0].ads && !isDealerOrder && !this.state.isReturnUser && (!combinationChecked || !combinationAvailable) &&
                                <div onClick={this.clickSweepstakeAds}><SweepstakeAds campaign={this.props.campaigns[0]} available={combinationChecked && combinationAvailable}/></div>
                            }
                        </CombinationActionContainer>
                    </CombinationContentArea>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={6} widescreen={6} floated='right'>
                    {/* <RestyleGuide
                                    available={this.state.combinationAvailable}
                                    combinationChecked = {this.state.combinationChecked}
                                    availableDescription={this.props.styleGuideInfo.availableDescription}
                                    unavailableDescription={this.props.styleGuideInfo.unavailableDescription} /> */}
                    <RestyleCombinationPricingOptions ref={this.plateFormatPricingRef} combinationFormats={this.props.restyleOptions} darkTheme={this.props.darkTheme}></RestyleCombinationPricingOptions>
                </Grid.Column>
            </Grid>
        );
    }
}

interface RestyleGuideProps {
    available: boolean
    combinationChecked: boolean
    availableDescription: string
    unavailableDescription: string
}

const RestyleGuide: FunctionComponent<RestyleGuideProps> = (data) => {
    const showingUnavailable = !data.available && data.combinationChecked;
    const headingColor = showingUnavailable ? theme.brand.colors.error : theme.brand.colors.black;
    const title = showingUnavailable ? "Not available for Restyle!" : "Restyle Guide";
    const desc = showingUnavailable ? data.unavailableDescription : data.availableDescription;
    return (
        <RestyleGuideContainer>
            <RestyleGuideHeading color={headingColor}>
                {title}
            </RestyleGuideHeading>
            <RestyleGuideDescription>
                {desc}
            </RestyleGuideDescription>
        </RestyleGuideContainer>
    )
}


export default RestyleCombinationFinder;