import React from 'react'

import {
    CombinationContentArea,
    FinderTitle,
    InfoBar,
    VehicleTypeOptions,
    VehicleTypeListItem,
    VehicleTypeItem,
    CombinationLetterCounterContainer,
    CombinationLetterCounter,
    CombinationInputContainer,
    CombinationInput,
    CombinationActionContainer,
    CombinationSearchResult,
    ResetCombination,
    PlateHole,
    CombinationInputRow,
    CombinationFinderHiddenElement,
    CombinationInputContainerRowTop,
    CombinationInputContainerRowBottom,
    RedesignMessage,
    SearchResultButtonWrapperColumn,
    ButtonWrapper,
    VehicleTypeLeftColumn,
    VehicleTypeRightColumn,
    VehicleTypeGrid,
    AvailabitityMessage,
    CombinationFinderHiddenElementWithMargin
} from './CombinationFinder.styles'
import { List, Grid, Responsive, Icon } from 'semantic-ui-react'
import { KpButton } from '@elements/index'
import theme from '@styles/theme'
import { navigate } from 'gatsby'
import { CombinationPricingOptions } from '@components/CombinationPricingOptions'
import { PlateFormat } from '@models/index'
import { PlateSuggestions } from '@components/PlateSuggestions'
import { CheckCombinationService } from '@services/CheckCombinationService'
import { VehicleType, CurrentPlateDesign, CombinationSettings, VehicleTypesSetting, TransactionType, TransactionTypesSetting } from '@models/ProductModels'
import QuickHelpInfoLink from './QuickHelpInfo'
import { CombinationReason, GA4Event, LeadGenStatus, LeadGenType, LeadSource, OrderTypes, ReCaptchaThreshold } from '@utils/Constant'
import { GAService, SubscriptionService } from '@services/index'
import { date } from '@storybook/addon-knobs'
import ReCaptcha from '@utils/reCaptcha'
import { ReCaptchaService } from '@services/ReCaptchaService'
import { Campaign } from '@models/Campaign/Campaign'
import SweepstakeCampaign from '../Campaign/SweepstakeCampaign'
import SweepstakeAds from '@components/Campaign/SweepstakeAds'
import { LOCALSTORAGE_CAMPAIGN_CURRENT, LOCALSTORAGE_LEAD, LOCALSTORAGE_LEADCOMBINATION, LOCALSTORAGE_RESERVATION } from '@redux/localStorage'
import { CampaignModel } from '@components/Campaign/CampaignModel'
import { getCurrentCheckout } from '@redux/localStorage/checkout'
import ReservedThisPlate from '@components/LeadGeneration/ReservedThisPlate'
import NotifyWhenAvailable from '@components/LeadGeneration/NotifyWhenAvailable'
import ThankYou from '@components/LeadGeneration/ThankYou'
import { LeadService } from '@services/LeadService'
import CancelThisPlate from '@components/LeadGeneration/CancelThisPlate'
import moment from 'moment'
import { isMobile } from '@utils/Helpers'
import VehicleTypeSelector from './VehicleTypeSelector'
import Cookies from "js-cookie";


interface FinderProps {
    title: string,
    plateformats: PlateFormat[]
    vehicleType: VehicleType
    transactionType: TransactionType
    combination: string
    isGift?: boolean
    plateDesign?: CurrentPlateDesign
    categoryName?: string
    apiUrl: string
    reCaptchaSiteKey: string
    reCaptchaSiteKeyV2: string
    campaign: Campaign,
    emailValidationToken: string
    alternativeMessage: string,
    isLeadGenEnabled: boolean,
    buynow?: boolean,
    notify?: boolean,
    carMake: any,
    lid?: string,
    email?: string
    designId: number
    darkTheme: boolean
}
interface FinderStates {
    vehicleType: VehicleType
    isCheckingCombination: boolean
    combination: string
    combinationChecked: boolean
    combinationAvailable: boolean
    oldCombination: string
    characterCombinationCount: number
    spacingCombinationCount: number,
    vehicleTheme: any,
    isForRedesign: boolean,
    isForUpgrade: boolean,
    reCaptchaToken?: string,
    reCaptchaError: string,
    isCampaignDialogOpen: boolean,
    isReturnUser: boolean,
    alreadyShow: boolean,
    toggleReserveThisPlate: boolean
    toggleNotify: boolean,
    showNotifyButton: boolean,
    toggleThankYou: boolean,
    showReserveButton: boolean,
    toggleCancelPlate: boolean,
    combinationForCancel: string,
    loading: boolean,
    toggleExpiry: boolean
    canProceed: boolean
}



class CombinationFinder extends React.Component<FinderProps, FinderStates>{
    plateFormatPricingRef: React.RefObject<CombinationPricingOptions>;
    plateSuggestionsRef: React.RefObject<PlateSuggestions>;

    constructor(props: FinderProps) {
        super(props);
        this.state = {
            vehicleType: props.vehicleType,
            combination: props.combination,
            combinationChecked: false,
            combinationAvailable: false,
            characterCombinationCount: 0,
            oldCombination: '',
            spacingCombinationCount: 0,
            isCheckingCombination: false,
            vehicleTheme: theme.vehicleTheme.dark,
            isForRedesign: false,
            isForUpgrade: false,
            reCaptchaToken: undefined,
            reCaptchaError: "",
            isCampaignDialogOpen: false,
            isReturnUser: false,
            alreadyShow: false,
            toggleReserveThisPlate: false,
            toggleNotify: false,
            showNotifyButton: false,
            toggleThankYou: false,
            showReserveButton: false,
            toggleCancelPlate: this.props.lid != "" && this.props.email != "",
            combinationForCancel: "",
            loading: false,
            toggleExpiry: false,
            canProceed: false
        }
        this.plateFormatPricingRef = React.createRef();
        this.plateSuggestionsRef = React.createRef();
    }

    componentDidMount() {
        if (this.state.combination !== '') {
            this.handleInputChange({ target: { value: this.state.combination } });
            this.handleCombinationCheck();
        }
        return LeadService.getCombination(
            this.props.apiUrl, this.props.lid || "", this.props.email || ""
        ).then((result) => {
            this.setState({
                combinationForCancel: result
            }, () => {
                localStorage.setItem(LOCALSTORAGE_LEADCOMBINATION, result);
            })

        });

    }

    getCombinationWidth = (combination: string) => {

        const largeCharLength = combination.replace(CombinationSettings.largeCharPattern, "").length;
        const smallCharLength = combination.replace(CombinationSettings.smallCharPattern, "").length;
        const spaceLength = combination.replace(CombinationSettings.spacePattern, "").length;
        const totalLength = (largeCharLength * this.state.vehicleType.largeCharWidth) + (smallCharLength * this.state.vehicleType.smallCharWidth) + (spaceLength * this.state.vehicleType.spaceCharWidth);

        return {
            largeCharacterLength: largeCharLength,
            smallCharacterLength: smallCharLength,
            spaceLength: spaceLength,
            totalWidth: totalLength
        }
    }

    handleInputChange = (event: any) => {
        this.setState({ combination: event.target.value, combinationChecked: false, toggleExpiry: false  });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();

        let combination = event.target.value;
        const combinationWidth = this.getCombinationWidth(combination);

        if (combinationWidth.totalWidth <= this.state.vehicleType.maxWidth)
            this.setState({
                characterCombinationCount: combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength,
                showReserveButton: combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength > 3,
                spacingCombinationCount: combinationWidth.spaceLength
            })

        if ((combinationWidth.largeCharacterLength + combinationWidth.smallCharacterLength) > this.state.vehicleType.maxChars || combinationWidth.totalWidth > this.state.vehicleType.maxWidth) {
            this.setState({
                combination: this.state.oldCombination,
                characterCombinationCount: this.getCombinationWidth(this.state.oldCombination).largeCharacterLength + this.getCombinationWidth(this.state.oldCombination).smallCharacterLength,
            });

        } else {
            var transformedInput = combination.replace(CombinationSettings.allowed, "");
            if (transformedInput !== combination) {
                this.setState({ combination: transformedInput });
            }
            this.setState({
                oldCombination: transformedInput
            });
        }

        if(combination.length >= 3 && combination.length <= 6){
            if (this.plateSuggestionsRef.current != null) {
                setTimeout(function() {
                    this.plateSuggestionsRef.current.loadSuggestions(combination)
                }.bind(this), 2000);
            };
            if(this.state.combinationAvailable && this.state.combinationChecked){
                this.setState({combinationAvailable: false, combinationChecked: false})
            }
        }
    }

    handleKeyPress = (event: any) =>{
        if (event.key === "Enter") {
            this.handleCombinationCheck();
        }
    };
    resetCombination = () => {
        this.setState({
            combination: '',
            combinationChecked: false,
            combinationAvailable: false,
            characterCombinationCount: 0
        }, () => {
            Cookies.set('click-notification', "false");
            Cookies.set('click-reservation', "false");
        });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();
    }

    handleCombinationCheck = (isSuggestion = false) => {
        this.setState({
            isCheckingCombination: true
        })
        const combination = this.state.combination;
        this.checkCombinationAvailable(combination).then((combinationCheckResult) => {
            var isAvailable = combinationCheckResult.Available
            var reservedCombination = localStorage.getItem(LOCALSTORAGE_RESERVATION) || "|0";
            var dateNow = moment().format('YYYYMMDDHHmmss')
            var canProceed = reservedCombination.split('|')[0] === combination &&  parseInt(dateNow) < parseInt(reservedCombination.split('|')[1])? true : false;
            if (isAvailable) {
                if (this.plateSuggestionsRef.current != null) this.plateSuggestionsRef.current.loadSuggestions(combination);
                this.setState(
                    {
                        combinationChecked: true,
                        combinationAvailable: combinationCheckResult.Reason === CombinationReason.isLeadgenReserved ?  canProceed ? true : false : true,
                        isCheckingCombination: false,
                        isForUpgrade: combinationCheckResult.IsAvailableForUpgrade ? true : false,
                        isForRedesign: combinationCheckResult.IsAvailableForRedesign ? true : false,
                        showNotifyButton: combinationCheckResult.Reason === CombinationReason.isLeadgenReserved && !canProceed,
                        showReserveButton: !canProceed
                    });
                if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.selectCombinationFormat(combination);

                const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
                let campaignItem : CampaignModel
                if(campaignInLocal !== null){
                    campaignItem = JSON.parse(campaignInLocal) as CampaignModel;
                    if(campaignItem.campaignName == this.props.campaign.name && campaignItem.email){
                        this.setState({isReturnUser: true})
                    } else if(campaignItem.campaignName == this.props.campaign.name && !campaignItem.email) {
                        this.setState({alreadyShow: true})
                    }
                } else {
                    localStorage.setItem(LOCALSTORAGE_CAMPAIGN_CURRENT, JSON.stringify({campaignName: this.props.campaign.name, email: "", time:Date.now()}));
                }
                setTimeout(() => {
                    this.setState({isCampaignDialogOpen: true})
                }, 1500)
            } else {
                this.setState(
                    {
                        combinationChecked: true,
                        combinationAvailable: false,
                        isCheckingCombination: false,
                        isForUpgrade: combinationCheckResult.IsAvailableForUpgrade ? true : false,
                        isForRedesign: combinationCheckResult.IsAvailableForRedesign ? true : false,
                        isCampaignDialogOpen: false,
                        showNotifyButton: combinationCheckResult.Reason === CombinationReason.isLeadgenReserved
                    });
                    if (this.plateSuggestionsRef.current != null) {
                        setTimeout(function() {
                            this.plateSuggestionsRef.current.loadSuggestions(combination)
                        }.bind(this), 2000);
                    };
            }

            const saved = localStorage.getItem("checkCombination");
            if(saved) {
                let initialValue = JSON.parse(saved);
                var t = new Date();

                if (initialValue.combination == this.state.combination && initialValue.time > t.setSeconds(t.getSeconds() - 3)){
                    return
                }
            }
            localStorage.setItem("checkCombination", JSON.stringify({combination: this.state.combination, time:Date.now()}));

            GAService.trackEvent('chooseCombination', {
                combination: combination,
                orderType: OrderTypes.New,
                isAvailable: isAvailable,
                restyleVSnewdesign: "New Design",
            });
            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: combination,
                availableForRedesign: null,
                availableForUpgrade: null,
                isAvailable: isAvailable,
                orderType: OrderTypes.New,
                selectedSuggestion: isSuggestion ? combination : '',
                fromSuggestion: isSuggestion
            });
        });
    }

    checkCombinationAvailable = (combination: string) => {
        return CheckCombinationService.checkCombination(this.props.apiUrl, combination, this.state.vehicleType.id).then((combinationCheckResult) => {
            return combinationCheckResult;
        });
    }

    setCombinationSuggestion = (combination: string) => {
        this.setState({
            combination: combination
        }, () => {
            this.handleCombinationCheck(true);

            GAService.trackEvent("plateSuggestions", {
                combination: combination,
                isAvailable: true,
                orderType: OrderTypes.New,
                restyleVSnewdesign: 'New Design',
            })

            GAService.trackEventGA4({
                event: GA4Event.PlateSuggestions,
                selectedSuggestion: combination,
                isAvailable: true,
                orderType: OrderTypes.New,
                fromSuggestion: true,
                availableForRedesign: null,
                availableForUpgrade: null
            });
        })
    }
    chooseDesign = (designId: number, iOwnThisPlate: boolean = false) => {
        let transactionType = this.props.vehicleType.id !== this.state.vehicleType.id ? this.props.transactionType : this.props.transactionType
        if (iOwnThisPlate) {
            if (this.state.isForRedesign) {
                transactionType = TransactionTypesSetting.Redesign
            }
            if (this.state.isForUpgrade) {
                transactionType = TransactionTypesSetting.Upgrade
            }
            GAService.trackEvent('chooseCombination', {
                combination: this.state.combination,
                orderType: "Redesign",
                restyleVSnewdesign: "Redesign",
                isAvailable: this.state.isForRedesign || this.state.isForUpgrade
            });

            GAService.trackEventGA4({
                event: GA4Event.Search,
                searchedCombination: this.state.combination,
                availableForRedesign: this.state.isForRedesign,
                availableForUpgrade: this.state.isForUpgrade,
                isAvailable: this.state.isForRedesign || this.state.isForUpgrade,
                orderType: OrderTypes.Redesign,
                selectedSuggestion: '',
                fromSuggestion: false
            });
        }

        if (this.props.vehicleType.id !== this.state.vehicleType.id) {
            navigate("/select-a-style/?combination=" + this.state.combination, {
                state: {
                    combination: this.state.combination,
                    plateDesign: undefined,
                    vehicleType: this.state.vehicleType,
                    transactionType: transactionType,
                    categoryName: undefined,
                    isGift: this.props.isGift,
                    productid: null
                }
            });
        } else {
            navigate("/select-a-style/?combination=" + this.state.combination + (designId ? "&productid="+designId : ''), {
                state: {
                    combination: this.state.combination,
                    plateDesign: this.props.plateDesign,
                    vehicleType: this.state.vehicleType,
                    transactionType: transactionType,
                    categoryName: this.props.categoryName,
                    isGift: this.props.isGift,
                    productid: this.props.designId
                }
            });
        }
    }

    changeVehicleType = (vehicleType: VehicleType) => {
        let vehicleTheme = null
        switch (vehicleType.name) {
            case VehicleTypesSetting.Car.name:
                vehicleTheme = theme.vehicleTheme.dark
                break;
            default:
                vehicleTheme = theme.vehicleTheme.dark
                break;
        }

        this.setState({
            vehicleType: vehicleType,
            combination: '',
            characterCombinationCount: 0,
            combinationChecked: false,
            combinationAvailable: false,
            vehicleTheme: vehicleTheme
        });
        if (this.plateFormatPricingRef.current != null) this.plateFormatPricingRef.current.clearSelectCombinationFormat();
    }

    handleReservationNotification = (status: string) => {
        this.setState({
            toggleThankYou: status === "ToBeNotify" ? true : false,
            toggleExpiry: status === "Reserved" ? true : false,
            toggleNotify: false,
            toggleReserveThisPlate: false,
            showNotifyButton: false,
            showReserveButton: false,
            toggleCancelPlate: false
        }, () => {
            this.handleCombinationCheck();
        })
    }

    closeModal = () => {
        this.setState({ isCampaignDialogOpen: false })
    }

    toggleReserveThisPlate = () => {
        this.setState({ toggleReserveThisPlate: !this.state.toggleReserveThisPlate })
    }

    toggleCancelPlate = () => {
        this.setState({ toggleCancelPlate: !this.state.toggleCancelPlate })
    }

    toggleNotify = () => {
        this.setState({ toggleNotify: !this.state.toggleNotify })
    }

    toggleThankYou = () => {
        this.setState({
            toggleThankYou: !this.state.toggleThankYou
        }, () => {
            this.resetCombination();
        })
    }

    clickSweepstakeAds =() => {
        if(this.state.combinationAvailable){
            this.setState({ isCampaignDialogOpen: true })
            if(this.state.isReturnUser) {
                this.setState({ isReturnUser: false })
            }
            if(this.state.alreadyShow){
                this.setState({ alreadyShow: false })
            }
        } else{
            this.setState({ isCampaignDialogOpen: false })
        }
    }

    render() {
        var date = new Date();
        var stringDate = moment(date.setDate(date.getDate() + 3)).format("DD MMM YYYY");
        const { vehicleType, combination, combinationChecked, combinationAvailable, characterCombinationCount, vehicleTheme, isForRedesign, isForUpgrade, combinationForCancel } = this.state;
        const { title, campaign, darkTheme } = this.props;
        const isCampaignActive = campaign && ( new Date(campaign.validFrom) < new Date() && new Date() < new Date(campaign.expiresOn))

        const isNewCampaign = campaign && (campaign.transactionTypes.filter(c => c.name == 'New').length > 0)
        const checkout = getCurrentCheckout()
        const isDealerOrder = checkout && checkout.isDealerOrder ? true : false;

        var carMake = [];
        if(vehicleType.id === VehicleTypesSetting.Car.id){
            carMake = this.props.carMake.filter((x: any) => x.category === 'car')
        }
        if(vehicleType.id === VehicleTypesSetting.Motorbike.id){
            carMake = this.props.carMake.filter((x: any) => x.category === 'motorbike')
        }


        return (

            <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={10} widescreen={10} floated='left'>
                    <CombinationContentArea>
                        {
                            title && <FinderTitle textAlign='center'>{title}</FinderTitle>
                        }
                        <InfoBar withCombination={combinationChecked}>
                            <VehicleTypeOptions>
                                <List horizontal>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Car.name} darkTheme={darkTheme} selected={vehicleType.id === VehicleTypesSetting.Car.id} onClick={() => this.changeVehicleType(VehicleTypesSetting.Car)}>
                                            <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Car.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Car} characterCombinationCount={characterCombinationCount}/>
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Trailer.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Trailer.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Trailer)}>
                                            <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Trailer.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Trailer} characterCombinationCount={characterCombinationCount}/>
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                    <VehicleTypeListItem>
                                        <VehicleTypeItem id={"VT-" + VehicleTypesSetting.Motorbike.name} darkTheme={darkTheme} selected={vehicleType.name === VehicleTypesSetting.Motorbike.name} onClick={() => this.changeVehicleType(VehicleTypesSetting.Motorbike)}>
                                            <VehicleTypeSelector selected={vehicleType.id === VehicleTypesSetting.Motorbike.id} darkTheme={darkTheme} vehicleType={VehicleTypesSetting.Motorbike} characterCombinationCount={characterCombinationCount}/>
                                        </VehicleTypeItem>
                                    </VehicleTypeListItem>
                                </List>
                                <QuickHelpInfoLink darkTheme={darkTheme}/>
                            </VehicleTypeOptions>

                        </InfoBar>
                        <CombinationInputContainer vehicleTheme={vehicleTheme} darkTheme={darkTheme}>
                        <AvailabitityMessage>
                                    {combinationChecked && combinationAvailable && (<div style={{display:"inline-flex",color: "#3DAE2B", fontWeight: "bold"}}><svg className='svg-image' style={{marginRight:"4px"}} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99984 15.8334C13.0499 15.8334 16.3332 12.5502 16.3332 8.50008C16.3332 4.44999 13.0499 1.16675 8.99984 1.16675C4.94975 1.16675 1.6665 4.44999 1.6665 8.50008C1.6665 12.5502 4.94975 15.8334 8.99984 15.8334Z" stroke="#3DAE2B" stroke-width="2"/>
<path d="M5.66748 8.38803L7.96403 10.6847L12.3341 6.3147" stroke="#3DAE2B" stroke-width="2"/>
</svg> Plate Available</div>)}{combinationChecked && !combinationAvailable && (this.state.showNotifyButton ? (<div style={{display:"inline-flex", color: "#F3921B", fontWeight: "bold"}}><svg className='svg-image-notavailable' style={{marginRight:"6px"}} width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 8.5C8.06087 8.5 9.07828 8.07857 9.82843 7.32843C10.5786 6.57828 11 5.56087 11 4.5C11 3.43913 10.5786 2.42172 9.82843 1.67157C9.07828 0.921427 8.06087 0.5 7 0.5C5.93913 0.5 4.92172 0.921427 4.17157 1.67157C3.42143 2.42172 3 3.43913 3 4.5C3 5.56087 3.42143 6.57828 4.17157 7.32843C4.92172 8.07857 5.93913 8.5 7 8.5ZM5.57188 10C2.49375 10 0 12.4937 0 15.5719C0 16.0844 0.415625 16.5 0.928125 16.5H13.0719C13.5844 16.5 14 16.0844 14 15.5719C14 12.4937 11.5063 10 8.42813 10H5.57188Z" fill="#F3921B"/>
</svg>Plate Reserved</div>) : <div style={{display:"inline-flex", color: "#FF3333", fontWeight: "bold"}}><svg  className='svg-image' style={{marginRight:"6px"}} width="14" height="15" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.70615 2.20615C10.0968 1.81553 10.0968 1.18115 9.70615 0.790527C9.31553 0.399902 8.68115 0.399902 8.29053 0.790527L4.9999 4.08428L1.70615 0.793652C1.31553 0.403027 0.681152 0.403027 0.290527 0.793652C-0.100098 1.18428 -0.100098 1.81865 0.290527 2.20928L3.58428 5.4999L0.293652 8.79365C-0.0969726 9.18428 -0.0969726 9.81865 0.293652 10.2093C0.684277 10.5999 1.31865 10.5999 1.70928 10.2093L4.9999 6.91553L8.29365 10.2062C8.68428 10.5968 9.31865 10.5968 9.70928 10.2062C10.0999 9.81553 10.0999 9.18115 9.70928 8.79053L6.41553 5.4999L9.70615 2.20615Z" fill="#FF3333"/>
</svg>Plate Not Available</div>)}
                                </AvailabitityMessage>


                            <Grid>
                                <CombinationInputContainerRowTop>
                                    <Grid.Column width={4} floated='left'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                    <Grid.Column width={4} floated='right'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                </CombinationInputContainerRowTop>
                                <CombinationInputRow centered>
                                    <Grid.Column textAlign='center' width={16}>
                                        <CombinationInput aria-label='Search Your Combination' spellCheck='false' autocomplete="off" autocorrect="off" placeholder={this.state.vehicleType.id === 1 ? 'ABC123' : 'ABC12'} onChange={(event: any) => this.handleInputChange(event)} value={combination} vehicleTheme={vehicleTheme} darkTheme={darkTheme} onKeyPress={(event: any) => this.handleKeyPress(event)}/>
                                    </Grid.Column>
                                </CombinationInputRow>
                                <CombinationInputContainerRowBottom>
                                    <Grid.Column width={4} floated='left'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                    <Grid.Column width={4} floated='right'>
                                        <PlateHole floated='left' vehicleTheme={vehicleTheme}></PlateHole>
                                        <PlateHole floated='right' vehicleTheme={vehicleTheme}></PlateHole>
                                    </Grid.Column>
                                </CombinationInputContainerRowBottom>

                            </Grid>
                        </CombinationInputContainer>
                        <div style={{margin:"10px"}}/>
                        <CombinationActionContainer>
                            {
                                !combinationChecked &&
                                    <span id="cfca" ><KpButton fullWidth="mobile" id="cfca" loading={this.state.isCheckingCombination} disabled={characterCombinationCount <= 0} color={theme.brand.colors.blue} buttonType='primary' onClick={() => this.handleCombinationCheck()} darkTheme={true}>
                                        Check Availability
                                    </KpButton></span>
                            }
                            {
                                combinationChecked && combinationAvailable &&
                                <div>
                                     <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                        <ButtonWrapper>
                                            <KpButton fullWidth="mobile" id='cfc' color={theme.brand.colors.blue} buttonType='primary' onClick={()=>this.chooseDesign(this.props.designId,false)} darkTheme={true}>
                                                Continue
                                            </KpButton>
                                            { this.state.showReserveButton && this.props.isLeadGenEnabled && <KpButton fullWidth="mobile" id='buy-later' color={theme.brand.colors.blue} buttonType='darkModeSecondary' onClick={()=>this.toggleReserveThisPlate()} darkTheme={true}>
                                                Buy Later
                                            </KpButton>}
                                        </ButtonWrapper>                                    
                                    </Responsive>
                                    <Responsive minWidth={theme.responsive.tablet.minWidth}>
                                        <ButtonWrapper>
                                            { this.state.showReserveButton && this.props.isLeadGenEnabled && <KpButton fullWidth="mobile" id='buy-later' color={theme.brand.colors.blue} buttonType='darkModeSecondary' onClick={()=>this.toggleReserveThisPlate()} darkTheme={true}>
                                                Buy Later
                                            </KpButton>}
                                            <KpButton fullWidth="mobile" id='cfc' color={theme.brand.colors.blue} buttonType='primary' onClick={()=>this.chooseDesign(this.props.designId,false)} darkTheme={true}>
                                                Continue
                                            </KpButton>
                                        </ButtonWrapper>
                                    </Responsive>
                                    
                                    {this.state.toggleExpiry && <div style={{"marginTop":"15px","color":"#FFF"}}><Icon name='check circle outline' size='small'/>Plate Reserved until {stringDate}</div>}
                                    <br/><br/>
                                    <ResetCombination id='cfrc' onClick={this.resetCombination}>
                                        Reset Combination
                                    </ResetCombination>

                                    {isCampaignActive && !this.state.isReturnUser && this.state.isCampaignDialogOpen && isNewCampaign && !isDealerOrder && !this.state.alreadyShow &&
                                    <SweepstakeCampaign
                                        campaign={this.props.campaign}
                                        reCaptchaSiteKey={this.props.reCaptchaSiteKey}
                                        reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}
                                        apiUrl={this.props.apiUrl}
                                        emailValidationToken={this.props.emailValidationToken}
                                        modalOpen={true}
                                        onClose={this.closeModal}
                                        />}
                                </div>
                            }
                            {
                                <div>
                                    {combinationChecked && !combinationAvailable &&
                                        <div>
                                            <Responsive minWidth={theme.responsive.tablet.minWidth}>
                                                <RedesignMessage>
                                                    {(isForRedesign || isForUpgrade) ? this.props.alternativeMessage : 'Plate Reservations are for 3 days. Be the first to know when this plate becomes available.'}
                                                </RedesignMessage>
                                            </Responsive>

                                            { !this.state.showNotifyButton && !this.props.isLeadGenEnabled && <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                                <RedesignMessage>
                                                    {this.props.alternativeMessage}
                                                </RedesignMessage>
                                            </Responsive>}
                                            <Grid>
                                                <Grid.Row centered>
                                                {(isForRedesign || isForUpgrade) ?
                                                    <>
                                                        <SearchResultButtonWrapperColumn maxWidth={45} marginRight={12} mobile={8} tablet={5} computer={5} largeScreen={5} widescreen={5} textAlign='center'>
                                                            <span id='cfsa'><KpButton fullWidth="mobile" id='cfsa' textColor={theme.brand.colors.white} color={theme.brand.secondary} buttonType='darkModeSecondary' onClick={this.resetCombination}>
                                                                Start Again
                                                            </KpButton></span>
                                                        </SearchResultButtonWrapperColumn>
                                                        <SearchResultButtonWrapperColumn maxWidth={45} mobile={8} tablet={6} computer={6} largeScreen={6} widescreen={6} textAlign='center'>
                                                            <span id='rcfbnp'><KpButton fullWidth='mobile' color={theme.brand.colors.green} id='rcfbnp' buttonType='primary' onClick={() => this.chooseDesign(this.props.designId, true)} darkTheme={true}>
                                                                I Own This Plate
                                                            </KpButton></span>
                                                        </SearchResultButtonWrapperColumn>
                                                    </> : <>
                                                            <SearchResultButtonWrapperColumn maxWidth={93} mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign='center'>
                                                                <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                                                    {this.state.showNotifyButton && this.props.isLeadGenEnabled && <KpButton fullWidth="mobile"  color={theme.brand.colors.blue} buttonType='primary' onClick={()=>this.toggleNotify()} className="button-notify" darkTheme={true}>
                                                                        Notify Me
                                                                    </KpButton>}
                                                                    <span id='cfsa'><KpButton fullWidth="mobile" id='cfsa' textColor={theme.brand.colors.white} color={theme.brand.secondary} buttonType='darkModeSecondary' onClick={this.resetCombination} >
                                                                        Start Again
                                                                    </KpButton></span>
                                                                </Responsive>
                                                                <Responsive minWidth={theme.responsive.tablet.minWidth}>
                                                                    {this.state.showNotifyButton && this.props.isLeadGenEnabled && <KpButton fullWidth="mobile"  color={theme.brand.colors.blue} buttonType='primary' onClick={()=>this.toggleNotify()} className="button-notify" darkTheme={true}>
                                                                        Notify Me
                                                                    </KpButton>}
                                                                    <span id='cfsa'><KpButton fullWidth="mobile" id='cfsa' textColor={theme.brand.colors.white} color={theme.brand.secondary} buttonType='darkModeSecondary' onClick={this.resetCombination} >
                                                                        Start Again
                                                                    </KpButton></span>
                                                                </Responsive>                                                              
                                                                
                                                            </SearchResultButtonWrapperColumn>
                                                        </>
                                                    }
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    }

                                </div>
                            }
                            {
                                isCampaignActive && isNewCampaign && this.props.campaign.ads && !isDealerOrder && (!combinationChecked || !combinationAvailable) &&
                                <div onClick={this.clickSweepstakeAds}><SweepstakeAds campaign={campaign} available={combinationChecked && combinationAvailable}/></div>
                            }
                        </CombinationActionContainer>
                        <ReservedThisPlate
                            combination = {combination}
                            apiUrl={this.props.apiUrl}
                            modalOpen={this.state.toggleReserveThisPlate}
                            onClose={this.toggleReserveThisPlate}
                            carMake = {carMake}
                            handleReservationNotification = {this.handleReservationNotification}
                            emailValidationToken={this.props.emailValidationToken}
                            />

                        <NotifyWhenAvailable
                            combination = {combination}
                            apiUrl={this.props.apiUrl}
                            modalOpen={this.state.toggleNotify}
                            onClose={this.toggleNotify}
                            carMake = {carMake}
                            handleReservationNotification = {this.handleReservationNotification}
                            emailValidationToken={this.props.emailValidationToken}
                            />

                        <ThankYou
                            combination = {combination}
                            apiUrl={this.props.apiUrl}
                            modalOpen={this.state.toggleThankYou}
                            onClose={this.toggleThankYou}
                            />

                        {combinationForCancel && <CancelThisPlate
                            combination = {combinationForCancel}
                            apiUrl={this.props.apiUrl}
                            modalOpen={this.state.toggleCancelPlate}
                            onClose={this.toggleCancelPlate}
                            leadId={parseInt(`${this.props.lid}`)}
                            email={`${this.props.email}`}
                            handleReservationNotification = {this.handleReservationNotification}
                            />}

                    </CombinationContentArea>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={6} widescreen={6} floated='right'>
                    <Responsive minWidth={theme.responsive.tablet.minWidth}>
                        <CombinationFinderHiddenElement className={(!combinationAvailable && this.state.combination.length >= 3) || (combinationChecked && !combinationAvailable) ? 'hidden' : ''}>
                            <CombinationPricingOptions ref={this.plateFormatPricingRef} combinationFormats={this.props.plateformats}></CombinationPricingOptions>
                        </CombinationFinderHiddenElement>
                        <CombinationFinderHiddenElement className={(!combinationAvailable && this.state.combination.length >= 3) || (combinationChecked && !combinationAvailable) ? '' : 'hidden'}>
                            <PlateSuggestions apiUrl={this.props.apiUrl} vehicleType={this.state.vehicleType} ref={this.plateSuggestionsRef} selectSuggestion={this.setCombinationSuggestion} suggestionsToShow={12} combinationFormats={this.props.plateformats}></PlateSuggestions>
                        </CombinationFinderHiddenElement>            
                    </Responsive>
                    <Responsive maxWidth={theme.responsive.mobile.maxWidth}>                        
                        <CombinationFinderHiddenElementWithMargin className={(!combinationAvailable && this.state.combination.length >= 3) || (combinationChecked && !combinationAvailable) ? '' : 'hidden'}>
                            <PlateSuggestions apiUrl={this.props.apiUrl} vehicleType={this.state.vehicleType} ref={this.plateSuggestionsRef} selectSuggestion={this.setCombinationSuggestion} suggestionsToShow={12} combinationFormats={this.props.plateformats}></PlateSuggestions>
                        </CombinationFinderHiddenElementWithMargin>
                        <CombinationFinderHiddenElement className={(!combinationAvailable && this.state.combination.length >= 3) || (combinationChecked && !combinationAvailable) ? 'hidden' : ''}>
                            <CombinationPricingOptions ref={this.plateFormatPricingRef} combinationFormats={this.props.plateformats}></CombinationPricingOptions>
                        </CombinationFinderHiddenElement>                       
                    </Responsive>
                    
                </Grid.Column>

            </Grid>

        );
    }
}

export default CombinationFinder;