import styled from "@emotion/styled";
import mq from '@styles/mq'
import theme from "@styles/theme";
import { Grid } from "semantic-ui-react";

export const PlateSuggestionsContainer = styled.div`
    border-radius: 8px;
    border: 2px solid #C6C6C8;
    background-color: #000;
    ${mq({
        padding: ['20px 20px 8px 20px','32px 24px 20px 24px', '32px 24px 20px 24px']
    })};
`


export const PlateSuggestionsHeadingContainer = styled.div`
    ${mq({
        paddingBottom: ['0px','0px','0px']
    })};
    text-align: left;
    color: #fff;
    .icon_margin{
        margin-top: 4px;
    }
`

export const PlateSuggestionContainer = styled.div`
    cursor: pointer;
    border-radius: 4px;
    border: solid 2px #232323;
    background-color: #191919;
    text-align: center;
    ${mq({
        width: ['151px','179px','179px'],
        height:['42px','61px','61px']
    })}
    margin-bottom: 12px;

    > span {
        font-family: 'NZ Plate Webfont';
        ${mq({
            fontSize: ['30px','48px','48px'],
            lineHeight: ['42px','61px','61px']
        })};
        color: #fff;
    }
`
export const FormatWarpper = styled.div`
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        color: #fff;
`
export const PriceWarpper = styled.div`
        ${mq({
            fontSize: ['14px','14px','14px'],
        })};
        font-style: normal;
        font-weight: 500;
        color: #707070;
        float: right;

        span{
            ${mq({
                fontSize: ['16px','16px','16px'],
            })};
            color: #3DAE2B;
            font-weight:700;
        }
        
`
export const GridWrapper = styled(Grid)`
        margin: 0px !important;

    .plate-suggestion-divider{
        width: 100%;
        border-top: 1px solid #C6C6C8 !important;
        margin-left: 12px;
        margin-right: 12px;
    }
`
export const GridRightColomnWrapper = styled(Grid.Column)`
    div {
        float: right;
    }
`
export const FormatRow = styled(Grid.Row)`
    padding-bottom: 0px !important;
    padding-top: 10px !important;
`
export const PlateRow = styled(Grid.Row)`
    padding-top: 8px !important;
    padding-bottom: 0px !important;
`
export const PremiumGrid = styled(Grid)`
    width:110%;
`